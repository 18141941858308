
import './Contact.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin, faClock } from '@fortawesome/free-solid-svg-icons'
function Contact() {
    const sendMessage = () => {
        console.log('Send message')
    }
    return (
        <div className="contact">
            <div className="banner">
                <div className="title">Contact Us</div>
                <div className="image"><img src="Images/contact_img_0.png" alt="" /></div>
            </div>
            <div className="content">
                <div className="details">
                    <div className="first">
                        <div className="address">
                            <FontAwesomeIcon icon={faLocationPin} />
                            <span className='title'>Office address</span>
                            <span className='text'>Samrat Ashok Road, Chandan Nagar, Pune, Maharashtra, 411014</span>
                        </div>
                        <div className="time">
                            <FontAwesomeIcon icon={faClock} />
                            <span className='title'>Best time to reach us</span>
                            <span className='text'>Monday-Saturday 9am-6pm</span>

                        </div>
                    </div>
                    <div className="second">
                        <div className="email">
                            <FontAwesomeIcon icon={faClock} />
                            <span className='title'>Best time to reach us</span>
                            <span className='text'>Monday-Saturday 9am-6pm</span>
                        </div>
                        <div className="phone">
                            <FontAwesomeIcon icon={faClock} />
                            <span className='title'>Best time to reach us</span>
                            <span className='text'>Monday-Saturday 9am-6pm</span>
                        </div>
                    </div>

                </div>
                <div className="form">
                    <div className="title">Send Us A Message!</div>
                    <span className='msg'>We are happy to hear from you, leave us a message and we’ll get back to you shortly!</span>
                    <br />
                    <div className="fields">
                        <form action="">
                            <label htmlFor="name">Name</label>
                            <input type="Name" placeholder='Your Name' />
                            <label htmlFor="email">Email</label>
                            <input type="email" placeholder='Your Email' />
                            <label htmlFor="phone">Phone</label>
                            <input type="phone" placeholder='Your Phone' />
                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="mesg" cols={30} rows={5} placeholder='Your Message'></textarea>
                            <button className='primary-btn' type='button' onClick={() => { sendMessage() }}>Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
