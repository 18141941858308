import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import './Common.scss'
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import Contact from "./Components/Contact/Contact";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header></Header>
        <div className="spacer">
          &nbsp;
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
