
import './Home.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import Packages from '../Packages/Packages';
function Home() {
    let navigate = useNavigate();
    const handleClick = () => {
        navigate('/gallery')
    };
    
    return (
        <>
            <div className="home">
                <div className="left">
                    <span className="text">One Stop Solution To <br /><b>Pest-Free Home!</b></span>
                    <p className='para'>
                        Stop booking multiple services today! <br />
                        Effective, affordable, and family-friendly solutions, guaranteed!

                    </p>
                    <div className="btns">
                        <button className='primary-btn'>
                            Book Now
                        </button>
                        <button className='primary-btn'>
                            Request A Call-Back
                        </button>
                    </div>
                </div>
                <div className="right"><img src="Images/img_0.png"/></div>
            </div>
            <Packages/>
        </>
    );
}

export default Home;
