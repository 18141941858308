import './Header.scss';
import { useNavigate } from "react-router-dom";

function Header() {
    let navigate = useNavigate()
    const handleClick = (path: string) => {
        navigate(path)
    }
    return (
        <header>
            <div className="header">
                <div className="leftSection" onClick={() => { handleClick('/') }}><img src="Images/header_logo.png" /></div>
                <div className="rightSection">
                    <ul>
                        <li><a href="#" onClick={(event)=>{event?.preventDefault();handleClick('/')}}>About us</a></li>
                        <li><a href="#" onClick={(event)=>{event?.preventDefault();handleClick('/contact')}}>Contact us</a></li>
                        <li><a href="#" onClick={(event)=>{event?.preventDefault();handleClick('/')}}>History</a></li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;
