import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PackageCard.scss';
import { useNavigate } from "react-router-dom";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

function PackageCard() {
    let navigate = useNavigate()
    const handleClick = () => {
        navigate('/')
    }
    return (
        <div className="pkg-container">
            <div className="pkg-header">
                <span className="title">Golden</span>
            </div>
            <div className="pkg-body">
                <ul>
                    <li><span>Free inspection by our trained staff.</span></li>
                    <li><span>Eco-Freindly spray and gel are applied in every corner of the house.</span></li>
                    <li><span>Complete elimination of ants, rodents and cockroach control guaranteed.</span></li>
                    <li><span>Covers accessible cracks, nooks and crannies of room, and kitchens unreachable areas.</span></li>
                </ul>
            </div>
            <div className="pkg-footer">
                <div className="price">
                    <span>Price</span>
                    <span>₹<b>1200</b>Onwards</span>
                </div>
                <button className='primary-btn'>Book now</button>
            </div>
        </div>
    );
}

export default PackageCard;
