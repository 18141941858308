import './Footer.scss';

function Footer() {
    return (
        <footer>
            <div className="content">
                {/* <div className="logo-img">
                    <img src="Images/logo_img_0.png" alt="Logo Img" />
                </div> */}
                {/* <div className="socialMediaLinks">
                    <ul>
                        <li><a href="https://www.linkedin.com/in/ravindra-kauthale-841723123" target="_blank"><img
                            src="Images/img_5.png" alt="linkedin" /></a></li>
                        <li><a href="https://github.com/kauthaleravindra" target="_blank"><img src="Images/img_6.png"
                            alt="linkedin" /></a></li>
                        <li><a href="https://codestats.net/users/ravindra" target="_blank"><img src="Images/img_7.svg"
                            alt="linkedin" className="codeStat" /></a></li>
                    </ul>
                </div> */}
                <div className="contact">
                    <span className='list-title'>Contact</span>
                    <hr />
                    <b>Email</b> services@pestxindia.com
                    <b>Phone</b> (+91) 7588184258 <br />
                </div>
                <div className="our_work">
                    <span className='list-title'>Our Services</span>
                    <hr />
                    <ul>
                        <li>Homecare</li>
                        <li>Termite Control</li>
                        <li>Bed Bug Treatment</li>
                        <li>Mosquito Control</li>
                        <li>Lizard Control</li>
                    </ul>
                </div>

            </div>
            <div className="copyright">Copyright © 2024 PXI- Pest X India</div>
        </footer>
    );
}

export default Footer;
