import './Packages.scss';
import { useNavigate } from "react-router-dom";
import PackageCard from '../PackageCard/PackageCard';
function Packages() {
    let navigate = useNavigate()
    const handleClick = () => {
        navigate('/')
    }
    return (
        <div className="pkgs">
            <div className="container">
                <div className="header">
                    <span className="title">Pest Control Services Designed for <br/><b>Indian Households!</b></span>
                </div>
                <div className="pkgs-container">
                    <PackageCard />
                    <PackageCard />
                </div>
            </div>
        </div>
    );
}

export default Packages;
